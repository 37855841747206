import * as React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';
import type { FluidObject } from 'gatsby-image';
import type { HomeImagesFragment } from 'types/graphql-type';

type Props = HomeImagesFragment & {
  className?: string;
};

export const query = graphql`
  fragment HomeImages on PrismicHomeDataType {
    images {
      image {
        alt
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

const useNeedFallback = () => {
  const [needFallback, setNeedFallback] = React.useState<boolean>(false);
  React.useLayoutEffect(() => {
    setNeedFallback(
      window.navigator.userAgent.includes('Safari') &&
        !window.navigator.userAgent.includes('Chrome')
    );
  }, []);
  return needFallback;
};

const useIsAnimationStart = (slideCurrent: HTMLDivElement | null) => {
  const [isAnimationStart, setIsAnimationStart] = React.useState<boolean>(
    false
  );
  React.useEffect(() => {
    setIsAnimationStart(true);
    const intersectionObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsAnimationStart(true);
        } else {
          setIsAnimationStart(false);
        }
      });
    });
    if (slideCurrent) {
      intersectionObserver.observe(slideCurrent);
    }
    return () => {
      intersectionObserver.disconnect();
    };
  }, [slideCurrent]);
  return isAnimationStart;
};

type UseSlideControlProps = {
  isAnimationStart: boolean;
  imagesLength?: number | null;
};
const useSlideControl = ({
  isAnimationStart,
  imagesLength,
}: UseSlideControlProps) => {
  const [currentIndex, setCurrentIndex] = React.useState<number>(0);
  const [nextIndex, setNextIndex] = React.useState<number>(1);
  const [isSlidingOut, setIsSlidingOut] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (imagesLength && imagesLength > 1) {
      let slidingTimerId: NodeJS.Timeout;
      const intervalId: NodeJS.Timeout = setTimeout(() => {
        if (isAnimationStart) {
          setIsSlidingOut(true);
          slidingTimerId = setTimeout(() => {
            const distNextIndex =
              nextIndex < imagesLength - 1 ? nextIndex + 1 : 0;
            setNextIndex(distNextIndex);
            setCurrentIndex(
              distNextIndex === 0 ? imagesLength - 1 : distNextIndex - 1
            );
            setIsSlidingOut(false);
          }, 500);
        }
      }, 5000);
      return () => {
        clearTimeout(intervalId);
        clearTimeout(slidingTimerId);
      };
    }
  }, [imagesLength, isAnimationStart, nextIndex]);

  const handleClick = React.useCallback(
    (targetIndex: number, currentIndex: number) => {
      if (!imagesLength) {
        return;
      }
      if (targetIndex === currentIndex) {
        return;
      }
      setNextIndex(targetIndex);
      setIsSlidingOut(true);
      setTimeout(() => {
        setCurrentIndex(targetIndex);
        setIsSlidingOut(false);
        setNextIndex(targetIndex < imagesLength - 1 ? targetIndex + 1 : 0);
      }, 500);
    },
    [imagesLength]
  );

  return { currentIndex, nextIndex, isSlidingOut, handleClick };
};

export const Slide: React.FC<Props> = ({ className, images }) => {
  const slide = React.useRef<HTMLDivElement>(null);
  const needFallback = useNeedFallback();
  const isAnimationStart = useIsAnimationStart(slide.current);
  const {
    currentIndex,
    nextIndex,
    isSlidingOut,
    handleClick,
  } = useSlideControl({ isAnimationStart, imagesLength: images?.length });

  return (
    <StyleSlide
      className={`${className ?? ''} ${
        isAnimationStart ? 'animation-start' : ''
      }`}
      ref={slide}
    >
      <StyleSlideImages>
        {images &&
          images.length > 0 &&
          images.map((item, index) => (
            <StyleSlideImage
              key={
                item?.image?.localFile?.id
                  ? `home-image-${item.image.localFile.id}`
                  : `home-image-${index}`
              }
              className={`${index === currentIndex ? 'current' : ''} ${
                index === currentIndex && isSlidingOut ? 'sliding-out' : ''
              } ${index === nextIndex ? 'next' : ''}  ${
                index === nextIndex && isSlidingOut ? 'translating' : ''
              } ${needFallback ? 'fallback' : ''}
            `}
            >
              <StyleSlideImageInner>
                {item?.image?.localFile?.childImageSharp?.fluid && (
                  <Img
                    fluid={
                      item.image.localFile.childImageSharp.fluid as FluidObject
                    }
                    alt={item?.image.alt ?? undefined}
                  />
                )}
              </StyleSlideImageInner>
            </StyleSlideImage>
          ))}
      </StyleSlideImages>
      {images && images.length > 1 && (
        <StyleController>
          {images.map((item, index) => (
            <StyleButton
              key={
                item?.image?.localFile?.id
                  ? `home-image-button-${item.image.localFile.id}`
                  : `home-image-button-${index}`
              }
              className={`${index === currentIndex ? 'active' : ''}`}
              onClick={() => handleClick(index, currentIndex)}
            />
          ))}
        </StyleController>
      )}
    </StyleSlide>
  );
};

const StyleSlide = styled.div`
  --diff: 15px;
  --distTranslateX: 60px;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;

const StyleSlideImages = styled.div`
  width: 100vw;
  height: ${({ theme }) =>
    `calc(100vh - ${theme.headerHeight.spBar} - ${theme.headerHeight.spHome} + ${theme.headerHeight.spHomeMinusMargin})`};
  position: relative;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    height: 100vh;
  }
`;

const StyleSlideImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100vw + var(--diff));
  height: 100%;
  transform: translateX(calc(var(--diff) * -1));
  overflow: hidden;
  display: none;

  &.current,
  &.next {
    display: block;
  }
  &.current {
    z-index: 2;
  }

  &.next {
    z-index: 1;
  }

  .gatsby-image-wrapper {
    width: calc(100vw + var(--distTranslateX));
    height: 100%;
    transform: translateX(0);
  }

  .animation-start & {
    &.current.sliding-out {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      animation: slideClipPath 0.5s cubic-bezier(0.19, 1, 0.22, 1) forwards;
    }
    &.current,
    &.next.translating {
      .gatsby-image-wrapper {
        animation: translatePosition 11s linear forwards;
      }

      &.fallback {
        .gatsby-image-wrapper {
          animation: translatePositionFallback 11s linear forwards;
        }
      }
    }
  }

  @keyframes slideClipPath {
    0% {
      clip-path: polygon(
        0 0,
        100% 0,
        calc(100% + var(--diff)) 100%,
        var(--diff) 100%
      );
      -webkit-clip-path: polygon(
        0 0,
        100% 0,
        calc(100% + var(--diff)) 100%,
        var(--diff) 100%
      );
    }
    100% {
      clip-path: polygon(0 0, 0 0, var(--diff) 100%, var(--diff) 100%);
      -webkit-clip-path: polygon(0 0, 0 0, var(--diff) 100%, var(--diff) 100%);
    }
  }

  @keyframes translatePosition {
    0% {
      will-change: transform;
      transform: translateX(0);
    }
    100% {
      will-change: unset;
      transform: translateX(calc(var(--distTranslateX) * -1));
    }
  }

  @keyframes translatePositionFallback {
    /* NOTE:
     * transformでやると、safariでclip-pathのanimationが
     * 動作しないので、leftで指定する。理由不明。
     * -webkit-clip-pathだから？
     * ちょっと様子を見る
     */
    0% {
      will-change: margin-left;
      margin-left: 0;
    }
    100% {
      will-change: unset;
      margin-left: calc(var(--distTranslateX) * -1);
    }
  }
`;

const StyleSlideImageInner = styled.div`
  transform: translateX(var(--diff));
  height: 100%;
`;

const StyleController = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 20px;
  button {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const StyleButton = styled.button`
  --size: 10px;
  width: var(--size);
  height: var(--size);
  background-color: grey;
  border-radius: 100%;
  &.active {
    background-color: black;
  }
`;

export default Slide;
