import * as React from 'react';
import styled from '@emotion/styled';
import { Category } from './category';
import { nonNullableFilter } from 'src/helper/utils';
import type {
  Maybe,
  HomeProgramOverviewCategoryFragment,
} from 'types/graphql-type';

type Props = {
  className?: string;
  categories: Maybe<HomeProgramOverviewCategoryFragment>[];
};

const useNonNullableCategories = (categories: Props['categories']) =>
  React.useMemo(() => categories.filter(nonNullableFilter), [categories]);

export const Categories: React.VFC<Props> = ({ className, categories }) => {
  const NonNullableCategories = useNonNullableCategories(categories);
  return (
    <div className={className ?? ''}>
      {NonNullableCategories.map((category, index) => (
        <StyleCategory
          key={`home-program-overview-category-${index}`}
          {...category}
        />
      ))}
    </div>
  );
};

const StyleCategory = styled(Category)`
  & + & {
    margin-top: 5em;
  }
`;

export default Categories;
