import * as React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Img, { FluidObject } from 'gatsby-image';
import type { HomeIntroductionStaffFragment } from 'types/graphql-type';

type Props = HomeIntroductionStaffFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment HomeIntroductionStaff on PrismicHomeIntroductionStaffGroupType {
    introduction_staff_image {
      alt
      localFile {
        childImageSharp {
          fluid(maxWidth: 400, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    introduction_staff_name {
      text
    }
    introduction_staff_role {
      text
    }
    introduction_staff_position {
      text
    }
  }
`;

export const Staff: React.VFC<Props> = ({
  className,
  introduction_staff_image,
  introduction_staff_name,
  introduction_staff_role,
  introduction_staff_position,
}) => (
  <Wrapper className={className ?? ''}>
    {introduction_staff_role?.text && (
      <Role>{introduction_staff_role.text}</Role>
    )}
    {introduction_staff_image?.localFile?.childImageSharp?.fluid && (
      <Image
        fluid={
          introduction_staff_image.localFile.childImageSharp
            .fluid as FluidObject
        }
      />
    )}
    {introduction_staff_name?.text && (
      <Name>{introduction_staff_name.text}</Name>
    )}
    {introduction_staff_position?.text && (
      <Position>{introduction_staff_position.text}</Position>
    )}
  </Wrapper>
);

const Role = styled.p`
  font-size: ${({ theme }) => theme.fontSize.normal.sp};
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.large.pc};
  }
`;

const Image = styled(Img)`
  border-radius: 100%;
  max-width: 170px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5em;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    max-width: 200px;
  }
`;

const Name = styled.h4`
  line-height: 1.8;
  margin-top: 0.3em;
  font-size: ${({ theme }) => theme.fontSize.xxlarge.sp};
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.xxlarge.pc};
  }
`;

const Position = styled.p`
  font-size: ${({ theme }) => theme.fontSize.normal.sp};
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.normal.pc};
  }
`;

const Wrapper = styled.div`
  text-align: center;
  p {
    text-align: center;
  }
`;

export default Staff;
