import * as React from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import AreaCampusHontou from 'src/images/area_campus/mobile/area_hontou.inline.svg';
import AreaCampusKumejimaKeramaShotou from 'src/images/area_campus/mobile/area_kumejima_kerama_shotou.inline.svg';
import AreaCampusMiyakoShotou from 'src/images/area_campus/mobile/area_miyako_shotou.inline.svg';
import AreaCampusYaeyamaShotou from 'src/images/area_campus/mobile/area_yaeyama_shotou.inline.svg';
import AreaCampusYonagunijima from 'src/images/area_campus/mobile/area_yonagunijima.inline.svg';
import AreaCampusDaitouShotou from 'src/images/area_campus/mobile/area_daitou_shotou.inline.svg';

const useCampusRef = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    function handleClick(event: MouseEvent) {
      // navigation
      if (event.currentTarget) {
        void navigate(
          `/event/place/${(event.currentTarget as SVGElement).id}/`
        );
      }
    }
    if (ref.current) {
      const clickableAreasNodeList = ref.current.querySelectorAll<SVGElement>(
        '.hover'
      );
      // NOTE:
      // IE用にarrayに変換
      // IE無視していいなら、NodeListのforEachをそのまま使用できる
      const clickableAreasArray = Array.from(clickableAreasNodeList);
      clickableAreasArray.forEach((element) => {
        element.addEventListener('click', handleClick);
      });
      return () => {
        clickableAreasArray.forEach((element) => {
          element.removeEventListener('click', handleClick);
        });
      };
    }
  }, []);
  return ref;
};

export const MapMobile: React.FC = () => {
  const campusRef = useCampusRef();
  return (
    <StyleAreaCampusMapMobile ref={campusRef}>
      <StyleHontouBlock>
        <AreaCampusHontou />
      </StyleHontouBlock>
      <StyleBlock>
        <AreaCampusKumejimaKeramaShotou />
      </StyleBlock>
      <StyleBlock>
        <AreaCampusMiyakoShotou />
      </StyleBlock>
      <StyleBlock>
        <AreaCampusYaeyamaShotou />
      </StyleBlock>
      <Style2ColumnBlock>
        <div>
          <AreaCampusYonagunijima />
        </div>
        <div>
          <AreaCampusDaitouShotou />
        </div>
      </Style2ColumnBlock>
    </StyleAreaCampusMapMobile>
  );
};

const StyleAreaCampusMapMobile = styled.div`
  .title {
    polyline {
      fill: none;
      stroke: black;
    }
    text {
      font-size: ${({ theme }) => theme.fontSize.normal.sp};
      text-decoration: underline;
    }
  }
  .hover {
    cursor: pointer;
    .hover-map {
      opacity: 0;
      transition: opacity 0.07s linear;
    }
    .point {
      text {
        font-size: ${({ theme }) => theme.fontSize.normal.sp};
      }
    }
    &:hover {
      .hover-map {
        opacity: 1;
      }
    }
  }
`;

const StyleBlock = styled.div`
  border-bottom: 1px solid black;
`;

const StyleHontouBlock = styled.div`
  border-bottom: 1px solid black;
`;

const Style2ColumnBlock = styled.div`
  display: flex;
  > div {
    flex: 1 1 50%;
    &:last-of-type {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 1px;
        height: 60%;
        transform: translateY(-50%);
        background-color: black;
      }
    }
  }
`;

export default MapMobile;
