import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Content } from 'src/components/common';
import { Section, ChildSection } from 'src/components/home/common';
import { StaffList } from './staff-list';
import type { HomeIntroductionFragment } from 'types/graphql-type';

type Props = HomeIntroductionFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment HomeIntroduction on PrismicHomeDataType {
    introduction_title {
      text
    }
    introduction_content {
      html
    }
    introduction_staff {
      ...HomeIntroductionStaff
    }
    introduction_purpose {
      html
    }
  }
`;

export const Introduction: React.FC<Props> = ({
  className,
  introduction_title,
  introduction_content,
  introduction_staff,
  introduction_purpose,
}) => (
  <Section
    id="introduction"
    className={className ?? undefined}
    title={
      introduction_title?.text && (
        <StyleTitle>{introduction_title.text}</StyleTitle>
      )
    }
    content={
      <>
        {introduction_content?.html && (
          <StyleContent html={introduction_content.html} />
        )}
        {introduction_staff && <StaffList list={introduction_staff} />}
        {introduction_purpose?.html && (
          <ChildSection title="事業目的">
            <PurposeContent html={introduction_purpose.html} />
          </ChildSection>
        )}
      </>
    }
  />
);

const StyleTitle = styled.span`
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${40 / 15}rem !important;
  }
`;

const StyleContent = styled(Content)`
  @media (max-width: ${({ theme }) => theme.breakpoints.ipadVertical}px) {
    br {
      display: none;
    }
  }
`;

const PurposeContent = styled(Content)`
  ol {
    list-style: none;
    counter-reset: introductionList;
    li {
      padding-left: 1.5em;
      position: relative;
      &::before {
        counter-increment: introductionList;
        content: counter(introductionList);
        position: absolute;
        top: 0;
        left: 0;
        font-size: ${({ theme }) => theme.fontSize.xxlarge.sp};
        line-height: 1.3;
      }
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    ol {
      li {
        &::before {
          font-size: ${({ theme }) => theme.fontSize.xxlarge.pc};
          line-height: 1.4;
        }
      }
    }
  }
`;

export default Introduction;
