import * as React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import type { HomeProgramOverviewCategoryFragment } from 'types/graphql-type';

type Props = HomeProgramOverviewCategoryFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment HomeProgramOverviewCategory on PrismicHomeProgramOverviewCategoriesGroupType {
    program_overview_category_title {
      text
    }
    program_overview_category_color
    program_overview_category_content {
      html
    }
    program_overview_category_people {
      html
    }
  }
`;

export const Category: React.VFC<Props> = ({
  className,
  program_overview_category_title,
  program_overview_category_color,
  program_overview_category_content,
  program_overview_category_people,
}) => (
  <div className={className ?? ''}>
    {program_overview_category_title?.text && (
      <Title backgroundColor={program_overview_category_color ?? 'none'}>
        <span>{program_overview_category_title.text}</span>
      </Title>
    )}
    {program_overview_category_content?.html && (
      <Content
        dangerouslySetInnerHTML={{
          __html: program_overview_category_content.html,
        }}
      />
    )}
    {program_overview_category_people?.html && (
      <People
        dangerouslySetInnerHTML={{
          __html: program_overview_category_people.html,
        }}
      />
    )}
  </div>
);

const Title = styled.h4<{ backgroundColor: string }>`
  font-size: ${({ theme }) => theme.fontSize.xxxlarge.sp};
  &::before {
    content: '';
    display: inline-block;
    width: 0.8em;
    height: 0.8em;
    background-color: ${({ backgroundColor }) => backgroundColor};
    vertical-align: middle;
    margin-right: 0.5em;
  }
  span {
    vertical-align: middle;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.xxxlarge.pc};
  }
`;

const Content = styled.div`
  margin-top: 0.5em;
`;

const People = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  row-gap: 30px;
  column-gap: 40px;
  margin-top: 20px;
  @media (min-width: ${(props) => props.theme.breakpoints.ipadVertical + 1}px) {
    margin-top: 40px;
  }
  .block-img {
    display: flex;
    align-items: center;
    line-height: 1.6;
    font-size: ${({ theme }) => theme.fontSize.normal.sp};
    @media (min-width: ${(props) =>
        props.theme.breakpoints.ipadVertical + 1}px) {
      font-size: ${({ theme }) => theme.fontSize.normal.pc};
    }
    img {
      flex: 0 0 100px;
      max-width: 100px;
      margin-right: 25px;
      border-radius: 100%;
    }
    .caption {
      flex: 1;
    }
    b {
      font-size: ${({ theme }) => theme.fontSize.xxlarge.sp};
      font-weight: normal;
      display: block;
      line-height: 1.4;
      margin-bottom: 0.2em;
      @media (min-width: ${(props) =>
          props.theme.breakpoints.ipadVertical + 1}px) {
        font-size: ${({ theme }) => theme.fontSize.xxlarge.pc};
      }
    }
  }
`;

export default Category;
