import * as React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import type { HomeProgramOverviewMovieFragment } from 'types/graphql-type';

type Props = HomeProgramOverviewMovieFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment HomeProgramOverviewMovie on PrismicEmbedType {
    width
    height
    html
  }
`;

export const Movie: React.VFC<Props> = ({ className, width, height, html }) => {
  if (!html) {
    return null;
  }
  const paddingBottom =
    height && width ? `${(height / width) * 100}%` : `${(9 / 16) * 100}%`;
  return (
    <Wrapper
      className={className ?? ''}
      paddingBottom={paddingBottom}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

const Wrapper = styled.div<{ paddingBottom: string }>`
  position: relative;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100% !important;
    height: 100% !important;
    margin-bottom: 0;
  }
`;

export default Movie;
