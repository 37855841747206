export const buildThresholdList = (numSteps: number): number[] => {
  const thresholds = [];

  for (let i = 1.0; i <= numSteps; i++) {
    const ratio = i / numSteps;
    thresholds.push(ratio);
  }

  thresholds.push(0);
  return thresholds;
};

export function nonNullableFilter<T>(value: T): value is NonNullable<T> {
  return value !== null;
}
