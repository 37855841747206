import * as React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import {
  Slide,
  Introduction,
  ProgramOverview,
  Posts,
  AreaCampus,
  Outline,
} from 'src/components/home';
import LeadSVG from 'src/images/lead.inline.svg';
import { SEO } from 'src/components/common';
import type { PageProps } from 'gatsby';
import type { GetHomeQuery } from 'types/graphql-type';

type Props = PageProps<GetHomeQuery>;

export const query = graphql`
  query GetHome {
    prismicHome {
      data {
        ...HomeImages
        ...HomeIntroduction
        ...HomeProgramOverview
        ...HomePrograms
        ...HomeAreaCampus
        ...HomeOutline
      }
    }
  }
`;

export const Index: React.FC<Props> = ({ data: { prismicHome } }) => (
  <>
    <SEO />
    <StyleArticle>
      <SlideWrapper>
        <Slide {...prismicHome?.data} />
        <SvgWrapper>
          <LeadSVG aria-label="オンラインで考える地域芸能のこれから。わたしたちの日常が大きく変化した「今」。この「今」を生きるわたしたちは、「地域」のこと、人びとと芸能のかかわり、そして「継承する」という営みをどう捉え直すことができるのか。オンデマンド配信に夜八つのプログラムを通して、持続可能な、「地域芸能」のこれからを考えます。" />
        </SvgWrapper>
      </SlideWrapper>
      <Introduction {...prismicHome?.data} />
      <ProgramOverview {...prismicHome?.data} />
      <Posts {...prismicHome?.data} />
      <AreaCampus {...prismicHome?.data} />
      <Outline {...prismicHome?.data} />
    </StyleArticle>
  </>
);

const SvgWrapper = styled.div`
  position: absolute;
  z-index: 10;
  @media (max-width: ${({ theme }) => theme.breakpoints.ipadVertical}px) {
    bottom: 40px;
    right: 0;
    width: 100%;
    max-width: 600px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    margin-right: calc(50% - 50vw);
    bottom: 80px;
    right: 40px;
    width: 600px;
  }
  svg {
    max-height: 100%;
  }
`;

const SlideWrapper = styled.div`
  position: relative;
`;

const StyleArticle = styled.article`
  > * + * {
    @media (max-width: ${({ theme }) => theme.breakpoints.ipadVertical}px) {
      margin-top: calc(${({ theme }) => theme.margin.content.sp} * 2);
      &:first-of-type {
        /* NOTE:
          「はじめに」のみscroll用にpaddingを設定
         */
        margin-top: 0px;
        padding-top: 40px;
      }
    }
    @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
      margin-top: calc(${({ theme }) => theme.margin.content.pc} * 2);
      &:first-of-type {
        /* NOTE:
          「はじめに」のみscroll用にpaddingを設定
         */
        margin-top: 0;
        padding-top: ${({ theme }) => theme.margin.content.pc};
      }
    }
  }
`;

export default Index;
