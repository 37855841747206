import * as React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Content } from 'src/components/common';
import { Section, ChildSection } from 'src/components/home/common';
import { Categories } from './categories';
import { Movie } from './movie';
import type { HomeProgramOverviewFragment } from 'types/graphql-type';

type Props = HomeProgramOverviewFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment HomeProgramOverview on PrismicHomeDataType {
    program_overview_image {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    program_overview_year(formatString: "YYYY")
    program_overview_title {
      text
    }
    program_overview_content {
      html
    }
    program_overview_categories {
      ...HomeProgramOverviewCategory
    }
    program_overview_application {
      html
    }
    program_overview_movie {
      html
      ...HomeProgramOverviewMovie
    }
  }
`;

export const ProgramOverview: React.FC<Props> = ({
  className,
  program_overview_image,
  program_overview_year,
  program_overview_title,
  program_overview_content,
  program_overview_categories,
  program_overview_application,
  program_overview_movie,
}) => (
  <Section
    id="program-overview"
    className={className ?? ''}
    title={program_overview_title?.text ?? undefined}
    image={
      program_overview_image?.localFile?.childImageSharp?.fluid ?? undefined
    }
    label={program_overview_year ?? undefined}
    labelPosition="left"
    content={
      <>
        {program_overview_content?.html && (
          <Content html={program_overview_content.html} />
        )}
        {program_overview_categories && (
          <ChildSection title="講座内容">
            <Categories categories={program_overview_categories} />
          </ChildSection>
        )}
        {program_overview_application?.html && (
          <ChildSection title="申込方法">
            <ApplicationContent html={program_overview_application.html} />
          </ChildSection>
        )}
      </>
    }
  >
    {program_overview_movie?.html && (
      <ChildSection title="サンプルムービー">
        <Movie {...program_overview_movie} />
      </ChildSection>
    )}
  </Section>
);

const ApplicationContent = styled(Content)`
  /* NOTE:
    申込方法
   */
  a {
    text-decoration: underline;
    @media (hover: hover) {
      &:hover {
        text-decoration: none;
      }
    }
    &.focus {
      text-decoration: none;
    }
  }
  .small {
    font-size: 0.8em;
    display: inline-block;
    margin-top: 0.2em;
  }
  ol {
    display: flex;
    flex-direction: column;
    line-height: 1.6;
    list-style: none;
    counter-reset: programApplication;
    @media (max-width: ${(props) => props.theme.breakpoints.ipadVertical}px) {
      align-items: center;
    }
    @media (min-width: ${(props) =>
        props.theme.breakpoints.ipadVertical + 1}px) {
      flex-direction: row;
      justify-content: space-between;
    }
    li {
      width: 150px;
      height: 150px;
      border: 1px solid black;
      border-radius: 100%;
      text-align: center;
      &::before {
        counter-increment: programApplication;
        content: counter(programApplication);
        display: block;
        font-size: 2.6em;
        margin-top: 0.3em;
        line-height: 1.7;
      }
      @media (min-width: ${(props) =>
          props.theme.breakpoints.ipadVertical + 1}px) {
        width: 200px;
        height: 200px;
        &::before {
          line-height: 1.5;
        }
      }
    }
  }
`;

export default ProgramOverview;
