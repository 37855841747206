import * as React from 'react';
import styled from '@emotion/styled';
import { Staff } from './staff';
import { nonNullableFilter } from 'src/helper/utils';
import type { Maybe, HomeIntroductionStaffFragment } from 'types/graphql-type';

type Props = {
  className?: string;
  list: Maybe<HomeIntroductionStaffFragment>[];
};

const useNonNullableList = (list: Props['list']) =>
  React.useMemo(() => list.filter(nonNullableFilter), [list]);

export const StaffList: React.VFC<Props> = ({ className, list }) => {
  const nonNullableList = useNonNullableList(list);
  return (
    <Wrapper className={className ?? ''}>
      {nonNullableList.map((staff, index) => (
        <Staff key={`home-introduction-staff-${index}`} {...staff} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
`;

export default StaffList;
