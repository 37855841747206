import React, { useRef, useEffect } from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import AreaCampusSVGPC from 'src/images/area_campus/pc/area_campus.inline.svg';
import { buildThresholdList } from 'src/helper/utils';

const useCampusRef = () => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      const intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // NOTE:
              // animation delayの為に
              // dom操作をする
              const textNodeList = entry.target.querySelectorAll('.point');
              const textArray = Array.from(textNodeList);
              textArray.forEach((element, index) => {
                const circle = element.querySelector('circle');
                const text = element.querySelector('text');
                const rect = element.querySelector('rect');
                if (circle) {
                  circle.addEventListener('animationend', () => {
                    circle.classList.add('start-animation-end');
                  });
                  circle.style.animationDelay = `${0.8 + index * 0.1}s`;
                  circle.classList.add('show');
                }
                if (text) {
                  text.style.animationDelay = `${0.5 + index * 0.1}s`;
                  text.classList.add('show');
                }
                if (rect) {
                  rect.style.animationDelay = `${0.5 + index * 0.1}s`;
                  rect.classList.add('show');
                }
              });
              intersectionObserver.disconnect();
            }
          });
        },
        {
          rootMargin: '-50% 0px 0px 0px',
          threshold: buildThresholdList(20),
        }
      );
      intersectionObserver.observe(ref.current);
      return () => {
        intersectionObserver.disconnect();
      };
    }
  }, []);
  useEffect(() => {
    function handleClick(event: MouseEvent): void {
      // navigation
      if (event.currentTarget) {
        void navigate(
          `/event/place/${(event.currentTarget as SVGElement).id}/`
        );
      }
    }
    function handleMouseOver(event: MouseEvent): void {
      // animation restart
      if (event.currentTarget) {
        const circleNodeList = (event.currentTarget as SVGElement).querySelectorAll(
          'circle'
        );
        if (circleNodeList) {
          const circleArray = Array.from(circleNodeList);
          circleArray.forEach((circle, index) => {
            if (circle.classList.contains('start-animation-end')) {
              circle.classList.remove('show');
              circle.style.animationDelay = `${index * 0.1}s`;
              setTimeout(() => {
                circle.classList.add('show');
              }, 10);
            }
          });
        }
      }
    }
    if (ref.current) {
      const clickableAreasNodeList = ref.current.querySelectorAll<SVGElement>(
        '.hover'
      );
      // NOTE:
      // IE用にarrayに変換
      // IE無視していいなら、NodeListのforEachをそのまま使用できる
      const clickableAreasArray = Array.from(clickableAreasNodeList);
      clickableAreasArray.forEach((element) => {
        element.addEventListener('click', handleClick);
        element.addEventListener('mouseover', handleMouseOver);
      });
      return () => {
        clickableAreasArray.forEach((element) => {
          element.removeEventListener('click', handleClick);
          element.removeEventListener('mouseover', handleMouseOver);
        });
      };
    }
  }, []);
  return ref;
};

export const MapPC: React.FC = () => {
  const campusRef = useCampusRef();
  return (
    <StyleAreaCampusMapPC ref={campusRef}>
      <AreaCampusSVGPC />
    </StyleAreaCampusMapPC>
  );
};

const circleAnimation = keyframes`
  from {
    opacity: 1;
    r: 0;
  }
  65% {
    opacity: 1;
    r: 6;
  }
  85% {
    opacity: 1;
    r: 3.5;
  }
  to {
    opacity: 1;
    r: 5;
  }
`;

const textAnimation = keyframes`
  from {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%)
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
`;

const StyleAreaCampusMapPC = styled.div`
  #map {
    pointer-events: none;
  }

  .hover {
    .title {
      rect {
        fill: #d4d4d4;
      }
      text {
        font-size: ${({ theme }) => theme.fontSize.large.pc};
      }
    }

    .point {
      text {
        font-size: ${({ theme }) => theme.fontSize.normal.pc};
      }
    }
    .title,
    .hover-map {
      opacity: 0;
      transition: opacity 0.07s linear;
    }
    &:hover {
      .title,
      .hover-map {
        opacity: 1;
      }
      .points {
        pointer-events: none;
      }
    }
  }

  .point {
    circle {
      opacity: 0;
      &.show {
        animation: ${circleAnimation} 0.18s cubic-bezier(0.19, 1, 0.22, 1)
          forwards;
      }
    }
    text,
    rect {
      clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
      &.show {
        animation: ${textAnimation} 0.25s cubic-bezier(0.77, 0, 0.175, 1)
          forwards;
      }
    }
  }
`;

export default MapPC;
