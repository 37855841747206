import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { List } from 'src/components/post';
import { Section } from 'src/components/home/common';
import type {
  HomeProgramsFragment,
  GetHomePostQuery,
} from 'types/graphql-type';

type Props = HomeProgramsFragment & {
  className?: string;
};

type QueryProps = GetHomePostQuery;

export const fragment = graphql`
  fragment HomePrograms on PrismicHomeDataType {
    programs_image {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;

const query = graphql`
  query GetHomePost {
    posts: allPrismicPost(
      sort: { fields: [data___date], order: DESC }
      limit: 9
    ) {
      ...PostList
    }
  }
`;

export const Posts: React.FC<Props> = ({ className, programs_image }) => {
  const { posts }: QueryProps = useStaticQuery<QueryProps>(query);
  return (
    <Section
      id="posts"
      className={className ?? undefined}
      title="開催プログラム"
      image={programs_image?.localFile?.childImageSharp?.fluid ?? undefined}
      label="Programs"
      labelPosition="right"
    >
      <List {...posts} />
      <StyleLinkToEvent>
        <Link to="/event/">すべて見る</Link>
      </StyleLinkToEvent>
    </Section>
  );
};

const StyleLinkToEvent = styled.p`
  text-align: center;
  margin-top: 0;
  a {
    display: inline-block;
    line-height: 1;
    border: 1px solid black;
    padding: 0.5em;
    &:hover {
      text-decoration: none;
      border-color: transparent;
    }
  }
`;

export default Posts;
