import * as React from 'react';
import styled from '@emotion/styled';

type Props = {
  className?: string;
  title: string;
};

export const ChildSection: React.FC<Props> = ({
  className,
  title,
  children,
}) => (
  <section className={className ?? ''}>
    <Title>{title}</Title>
    <Content>{children}</Content>
  </section>
);

const Title = styled.h3`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.large.sp};
  @media (min-width: ${(props) => props.theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.large.pc};
  }
`;

const Content = styled.div`
  margin-top: 3em;
`;

export default ChildSection;
