import React, { useContext } from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Content } from 'src/components/common';
import { Section } from 'src/components/home/common';
import MobileWidthContext, {
  Props as MobileWidthContextProps,
} from 'src/contexts/mobile-width-context';
import { MapPC } from './map-pc';
import { MapMobile } from './map-mobile';
import type { HomeAreaCampusFragment } from 'types/graphql-type';

type Props = HomeAreaCampusFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment HomeAreaCampus on PrismicHomeDataType {
    area_campus_image {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    area_campus_title {
      text
    }
    area_campus_content {
      html
    }
  }
`;

export const AreaCampus: React.FC<Props> = ({
  area_campus_image,
  area_campus_title,
  area_campus_content,
}) => {
  const { isMobile } = useContext<MobileWidthContextProps>(MobileWidthContext);

  return (
    <Section
      id="area-campus"
      title={area_campus_title?.text ?? undefined}
      image={area_campus_image?.localFile?.childImageSharp?.fluid ?? undefined}
      label={
        <span>
          Area
          <br />
          Campus
        </span>
      }
      labelPosition="right"
      content={
        area_campus_content?.html && <Content html={area_campus_content.html} />
      }
    >
      <StyleMap>
        {isMobile && <MapMobile />}
        {!isMobile && <MapPC />}
        {/* <img src={area_campus_image} alt="エリアキャンパスマップ" /> */}
      </StyleMap>
    </Section>
  );
};

const StyleMap = styled.figure`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;

  .hover {
    cursor: pointer;
    .hover-map {
      path {
        opacity: 0.5;
        fill: #aaaaaa;
      }
    }
    .clickable {
      path,
      polygon,
      rect {
        opacity: 0;
      }
    }
    .point {
      circle,
      text {
        fill: #ff0000;
      }
      rect {
        fill: #ffffff;
      }
    }
  }
`;

export default AreaCampus;
