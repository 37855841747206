import * as React from 'react';
import Img, { FluidObject } from 'gatsby-image';
import styled from '@emotion/styled';
import { buildThresholdList } from 'src/helper/utils';
import MobileWidthContext, {
  Props as MobileWidthContextProps,
} from 'src/contexts/mobile-width-context';
import type { GatsbyImageSharpFluid_WithWebpFragment } from 'types/graphql-type';

type Props = {
  id: string;
  className?: string;
  title?: React.ReactNode;
  content?: React.ReactNode;
  image?: GatsbyImageSharpFluid_WithWebpFragment;
  imagePosition?: string;
  label?: React.ReactNode;
  labelPosition?: 'left' | 'right';
};

const useShow = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [show, setShow] = React.useState<boolean>(false);
  const { isMobile } = React.useContext<MobileWidthContextProps>(
    MobileWidthContext
  );
  React.useEffect(() => {
    if (ref.current) {
      const intersectionObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setShow(true);
              intersectionObserver.disconnect();
            }
          });
        },
        {
          rootMargin: isMobile ? '0px' : '-250px',
          threshold: buildThresholdList(20),
        }
      );
      intersectionObserver.observe(ref.current);
      return () => {
        intersectionObserver.disconnect();
      };
    }
  }, [isMobile]);
  return { ref, show };
};

export const Section: React.FC<Props> = ({
  id,
  title,
  content,
  children,
  image,
  imagePosition = 'center center',
  label,
  labelPosition = 'left',
}) => {
  const { ref, show } = useShow();
  return (
    <Wrapper
      id={id ? id : ''}
      className={`home-section ${show ? 'show' : ''}`}
      ref={ref}
    >
      {(title || image) && (
        <Header>
          {image && (
            <ImgWrapper>
              <StyledImg
                imagePosition={imagePosition}
                fluid={image as FluidObject}
              />
              {label && (
                <Label className={`position-${labelPosition}`}>{label}</Label>
              )}
            </ImgWrapper>
          )}
          {title && <h2>{title}</h2>}
        </Header>
      )}
      {(content || children) && (
        <ContentWrapper>
          {content && <Content>{content}</Content>}
          {children && children}
        </ContentWrapper>
      )}
    </Wrapper>
  );
};

const StyledImg = styled(Img)<{ imagePosition: string }>`
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);

  img {
    object-position: ${({ imagePosition }) => imagePosition} !important;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.ipadVertical}px) {
    max-height: 300px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.ipadVertical + 1}px) {
    max-height: 750px;
  }
`;

const Label = styled.span`
  display: block;
  position: absolute;
  z-index: 1;
  font-family: ${({ theme }) => theme.fontFamily.garamond};
  line-height: 0.8;
  bottom: -0.65em;
  &.position-left {
    left: 0;
  }
  &.position-right {
    right: 0;
    text-align: right;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.ipadVertical}px) {
    font-size: 12vw;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.ipadVertical + 1}px) {
    &.position-left {
      font-size: clamp(90px, 11vw, 160px);
    }
    &.position-right {
      font-size: clamp(60px, 8vw, 160px);
    }
  }
`;

const ImgWrapper = styled.div`
  position: relative;
`;

const Header = styled.header`
  text-align: center;
  h2 {
    margin-bottom: 0;
    font-size: ${({ theme }) => theme.fontSize.xxxlarge.sp};
    @media (min-width: ${(props) =>
        props.theme.breakpoints.ipadVertical + 1}px) {
      font-size: ${({ theme }) => theme.fontSize.xxxlarge.pc};
    }
  }
  * + h2 {
    margin-top: calc(${({ theme }) => theme.margin.content.sp} * 2);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    * + h2 {
      margin-top: ${({ theme }) => theme.margin.content.pc};
    }
  }
`;

const Content = styled.div`
  font-size: ${({ theme }) => theme.fontSize.large.sp};

  p {
    text-align: justify;
  }

  > * + * {
    margin-top: ${({ theme }) => theme.margin.content.sp};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    font-size: ${({ theme }) => theme.fontSize.large.pc};
    max-width: ${({ theme }) => theme.maxWidthText};
    margin-left: auto;
    margin-right: auto;
    > * + * {
      margin-top: ${({ theme }) => theme.margin.content.pc};
    }
  }
`;

const ContentWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin.content.sp};

  > * + * {
    margin-top: ${({ theme }) => theme.margin.content.sp};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    margin-top: ${({ theme }) => theme.margin.content.pc};
    > * + * {
      margin-top: ${({ theme }) => theme.margin.content.pc};
    }
  }
`;

const Wrapper = styled.section`
  /* padding-top: ${({ theme }) => theme.margin.content.sp};
  padding-bottom: ${({ theme }) => theme.margin.content.sp}; */
  transform: translateY(7px);
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.3s linear;
  &.show {
    opacity: 1;
    transform: none;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    /* padding-top: ${({ theme }) => theme.margin.content.pc};
    padding-bottom: ${({ theme }) => theme.margin.content.pc}; */
  }
`;

export default Section;
