import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Content } from 'src/components/common';
import { Section } from 'src/components/home/common';
import type { HomeOutlineFragment } from 'types/graphql-type';

type Props = HomeOutlineFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment HomeOutline on PrismicHomeDataType {
    outline_image {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    outline_title {
      text
    }
    outline_content {
      html
    }
  }
`;

export const Outline: React.FC<Props> = ({
  outline_image,
  outline_title,
  outline_content,
}) => (
  <Section
    id="outline"
    title={outline_title?.text ?? undefined}
    image={outline_image?.localFile?.childImageSharp?.fluid ?? undefined}
    content={
      outline_content?.html && <StyleContent html={outline_content.html} />
    }
  ></Section>
);

const StyleContent = styled(Content)`
  font-size: ${({ theme }) => theme.fontSize.normal.sp};
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.normal.pc};
  }

  h3 {
    font-size: ${({ theme }) => theme.fontSize.normal.sp};
    font-weight: bold;
    @media (max-width: ${(props) => props.theme.breakpoints.ipadVertical}px) {
      text-align: left;
    }
    @media (min-width: ${(props) =>
        props.theme.breakpoints.ipadVertical + 1}px) {
      font-size: ${({ theme }) => theme.fontSize.normal.pc};
    }

    & + * {
      margin-top: 1.5em;
    }
  }

  > * + h3 {
    margin-top: 3em;
  }

  p {
    text-align: left;
    @media (min-width: ${(props) =>
        props.theme.breakpoints.ipadVertical + 1}px) {
      text-align: center;
    }
  }

  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

export default Outline;
